<template>
    <client-only>
        <DropdownMenu menuName="share-options" labelVariant="body-large" class="control nav-link" :isArrow="false">
            <template #label>
                <UtilityButton icon="share" class="share-button" />
            </template>

            <template #dropdown>
                <ul class="share-items">
                    <li class="share-item">
                        <IconLink
                            class="share-link"
                            :destination="`mailto:?subject=${shareTitle}&body=${shareTitle}%0D%0A${shareUrl}`"
                            icon="email"
                            label="Email"
                            :type="`social`"
                        />
                    </li>
                    <li class="share-item">
                        <IconLink
                            class="share-link"
                            :destination="`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`"
                            icon="facebook"
                            label="Facebook"
                            :type="`social`"
                        />
                    </li>
                    <li class="share-item">
                        <IconLink
                            class="share-link"
                            :destination="`https://twitter.com/intent/tweet?text=${shareTitle}%0D%0A${shareUrl}`"
                            icon="twitter"
                            label="Twitter"
                            :type="`social`"
                        />
                    </li>
                    <li class="share-item">
                        <IconLink
                            class="share-link"
                            :destination="`https://t.me/share/url?url=${shareUrl}&text=${shareTitle}`"
                            icon="telegram"
                            label="Telegram"
                            :type="`social`"
                        />
                    </li>
                </ul>
            </template>
        </DropdownMenu>
    </client-only>
</template>

<script setup>
const route = useRoute();
const shareUrl = computed(() => {
    if (process.client) {
        return `${window.location.origin}${route.fullPath}`;
    } else {
        return '';
    }
});

const shareTitle = computed(() => {
    if (process.client) {
        return document.title;
    } else {
        return '';
    }
});
</script>

<style lang="scss" scoped>
.share-item {
    padding: 1rem;
    min-width: 21rem;

    &:not(:last-child) {
        border-bottom: $border;
    }
}
</style>
